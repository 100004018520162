/* eslint-disable */
export class Conf {

  static idempiereUrl = "https://idtest.seritec.eu/"

  static windowTitle = 'CERTIFICO SERITEC'

  static buildNo = "20240926150630"

}

import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/login.vue'
import VerbaleSP from "./views/verbaleSP"
import Home from "./views/home"
import Firma from "./views/modificaFirma"
import VerbaleGVR from "./views/verbaleGVR"
import VerbaliRecenti from "./views/verbaliRecenti"
import VerbaleDismissioneSPSC from "./views/verbaleDismissioneSPSC"
import VerbaleDismissioneGVR from "./views/verbaleDismissioneGVR"
import VerbaleFuniCatene from "./views/verbaleFuniCatene"
import EsportazioneVerbali from './views/esportazioneVerbali.vue'
import ProgettiDaFatturare from './views/progettiDaFatturare.vue'
import DownloadOrder from './views/downloadOrder.vue'
import Veicoli from './views/veicoli.vue'
import RicercaVerbale from './views/ricercaVerbale.vue'
import PianificazioneAttività from './views/pianificazioneAttivita.vue'
import GoogleMaps from './views/googleMaps.vue'
import Smartcal from './views/smartcal.vue'
import ScadenziarioVerificheRidotto from './views/scadenziarioVerificheRidotto.vue'
import VerbaleTaraturaValvole from './views/verbaleTaraturaValvole.vue'

Vue.use(Router)

export default new Router({
  //mode: 'history',
  routes: [{
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path:"/verbaleSPSC",
      name:"verbaleSP",
      component:VerbaleSP,
    },
    {
      path:"/",
      name:"Home",
      component:Home
    },
    {
      path:"/firma",
      name:"firma",
      component:Firma,
      meta: { requiresAuth: true }
    },
    {
      path:"/verbaleGVR",
      name:"Verbale GVR",
      component:VerbaleGVR
    },
    {
      path:"/recenti",
      name: "Verbali recenti",
      component:VerbaliRecenti,
      meta: { requiresAuth: true }
    },
    {
      path:"/verbaleDismissioneSPSC",
      name: "Verbale dismissione",
      component:VerbaleDismissioneSPSC
    },
    {
      path:"/verbaleDismissioneGVR",
      name: "Verbale dismissione GVR",
      component:VerbaleDismissioneGVR
    },
    {
      path:"/verbaleFuniCatene",
      name: "Verbale Funi e Catene",
      component:VerbaleFuniCatene
    },
    {
      path:"/esportazioneVerbali",
      name: "Esportazione Verbali",
      component:EsportazioneVerbali
    },
    {
      path:"/progettiDaFatturare",
      name: "Progetti da Fatturare",
      component:ProgettiDaFatturare
    },
    {
      path:"/downloadOrder",
      name: "Download Order",
      component:DownloadOrder
    },
    {
      path:"/veicoli",
      name: "Veicoli",
      component:Veicoli,
      meta: { requiresAuth: true }
    },
    {
      path:"/ricercaVerbale",
      name: "Ricerca Verbale",
      component: RicercaVerbale,
      meta: { requiresAuth: true }
    },
    {
      path:"/pianificazioneAttivita",
      name: "Pianificazione Attività",
      component: PianificazioneAttività,
      meta: { requiresAuth: true}
    },
    {
      path:"/googleMaps",
      name: "Google Maps",
      component: GoogleMaps,
      meta: { requiresAuth: true}
    },
    {
      path:"/smartcal",
      name: "Smartcal",
      component: Smartcal,
      meta: { requiresAuth: true}
    },
    {
      path:"/scadenziarioVerificheRidotto",
      name: "Scadenziario Verifiche Ridotto",
      component: ScadenziarioVerificheRidotto,
      meta: { requiresAuth: true}
    },
    {
      path:"/verbaleTaraturaValvole",
      name: "Verbale Taratura Valvole",
      component: VerbaleTaraturaValvole,
      meta: { requiresAuth: true}
    }
  ]
})
<template>
  <span v-if="tab">
    <h4>{{ tab.Name }}</h4>
    <br />
    <v-form v-model="form" ref="form">
      <v-row v-for="(row, i) in fields" :key="i" wrap>
        <v-col :cols="12" v-if="row.group"
          ><br />
          <v-banner outlined
            ><h3>{{ row.group }}</h3></v-banner
          >
        </v-col>
        <v-col
          v-for="(field, index) in row.fields"
          :key="index"
          :cols="field.cols"
          :offset="field.offset"
        >
          <TabGridTable
            v-if="field.Included_Tab_ID"
            :record_id="record_id"
            :ad_tab_id="field.Included_Tab_ID"
            :parent_tab_id="ad_tab_id"
          ></TabGridTable>
          <v-text-field
            :label="field.Trl[language].Name"
            hide-details
            v-if="field.Column.Reference.Name == 'String'"
            dense
            outlined
            v-model="record[field.Column.ColumnName]"
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></v-text-field>
          <v-text-field
            :label="field.Trl[language].Name"
            hide-details
            v-if="field.Column.Reference.Name == 'URL'"
            type="URL"
            dense
            outlined
            v-model="record[field.Column.ColumnName]"
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></v-text-field>
          <v-text-field
            :label="field.Trl[language].Name"
            hide-details
            v-else-if="field.Column.Reference.Name == 'Integer'"
            type="Number"
            dense
            outlined
            v-model="record[field.Column.ColumnName]"
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></v-text-field>
          <v-text-field
            :label="field.Trl[language].Name"
            hide-details
            v-else-if="field.Column.Reference.Name == 'Number'"
            type="Number"
            dense
            outlined
            v-model="record[field.Column.ColumnName]"
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></v-text-field>
          <v-text-field
            :label="field.Trl[language].Name"
            hide-details
            v-else-if="field.Column.Reference.Name == 'Amount'"
            type="Number"
            dense
            outlined
            v-model="record[field.Column.ColumnName]"
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></v-text-field>
          <v-text-field
            :label="field.Trl[language].Name"
            hide-details
            v-else-if="field.Column.Reference.Name == 'Costs+Prices'"
            type="Number"
            dense
            outlined
            v-model="record[field.Column.ColumnName]"
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></v-text-field>
          <v-textarea
            :label="field.Trl[language].Name"
            hide-details
            v-else-if="field.Column.Reference.Name == 'Text'"
            dense
            outlined
            v-model="record[field.Column.ColumnName]"
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></v-textarea>
          <v-checkbox
            :label="field.Trl[language].Name"
            hide-details
            v-else-if="field.Column.Reference.Name == 'Yes-No'"
            v-model="record[field.Column.ColumnName]"
            true-value="Y"
            false-value="N"
            :disabled="isDisabled(field)"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></v-checkbox>
          <SelectionTable
            v-else-if="field.Column.Reference.Name == 'Table Direct'"
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></SelectionTable>
          <SelectionTable
            v-else-if="field.Column.Reference.Name == 'Table'"
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></SelectionTable>
          <SelectionTable
            v-else-if="field.Column.Reference.Name == 'Search'"
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></SelectionTable>
          <SelectionList
            v-else-if="field.Column.Reference.Name == 'List'"
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></SelectionList>
          <MultipleSelectionList
            v-else-if="
              field.Column.Reference.Name == 'Chosen Multiple Selection List'
            "
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></MultipleSelectionList>
          <MultipleSelectionTable
            v-else-if="
              field.Column.Reference.Name == 'Chosen Multiple Selection Search'
            "
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></MultipleSelectionTable>
          <MultipleSelectionTable
            v-else-if="
              field.Column.Reference.Name == 'Chosen Multiple Selection Table'
            "
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></MultipleSelectionTable>
          <SelectionTable
            v-else-if="field.Column.Reference.Name == 'Locator (WH)'"
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></SelectionTable>
          <SelectionTable
            v-else-if="field.Column.Reference.Name == 'ID'"
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></SelectionTable>
          <SelectionTable
            v-else-if="field.Column.Reference.Name == 'Product Attribute'"
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></SelectionTable>
          <DataPicker
            v-else-if="field.Column.Reference.Name == 'Date'"
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></DataPicker>
          <DataPicker
            v-else-if="field.Column.Reference.Name == 'Date+Time'"
            v-model="record[field.Column.ColumnName]"
            :column="field.Column"
            :label="field.Trl[language].Name"
            dense
            outlined
            :rules="getRules(field)"
            :disabled="isDisabled(field)"
            :clearable="!field.Column.IsMandatory"
            :title="field.Trl[language].Description"
            @change="emitChange(field)"
          ></DataPicker>
          <span v-else-if="field.Column.Reference.Name == 'Signature'">
            <h4>{{ field.Trl[language].Name }}</h4>
            <VueSignaturePad
              style="border: 1px solid #6c757d"
              :id="field.Column.ColumnName"
              width="702px"
              height="180px"
              :ref="field.Column.ColumnName"
              :title="field.Trl[language].Description"
            />
            <v-btn @click="clearSignature(field)">Cancella Firma</v-btn>
          </span>
        </v-col>
      </v-row>
    </v-form>
    <br />
    <v-flex d-flex>
      <v-btn color="primary" @click="save" v-if="!hideSave">Salva</v-btn>
      <v-btn
        text
        @click="copyRecord"
        :disabled="record_id <= 0"
        v-if="!hideCopy"
        ><v-icon>mdi-content-copy</v-icon>Copia</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        v-if="!hideDelete"
        text
        color="error"
        :disabled="record_id <= 0"
        @click="deleteRecord"
        ><v-icon>mdi-delete</v-icon>Elimina</v-btn
      >
    </v-flex>
    <span v-if="!hideTabs && tab.ChildTabs.length">
      <br />
      <br />
      <v-divider></v-divider>
      <v-tabs v-model="tabs">
        <v-tab v-for="wintab in tab.ChildTabs" :key="wintab.AD_Tab_ID">{{
          wintab.Name
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item v-for="wintab in tab.ChildTabs" :key="wintab.AD_Tab_ID">
          <TabGridTable
            :ad_tab_id="wintab.AD_Tab_ID"
            :parent_tab_id="tab.AD_Tab_ID"
            :record_id="record_id"
            :language="language"
          ></TabGridTable>
        </v-tab-item>
      </v-tabs-items>
    </span>

    <v-snackbar v-model="snackbar" color="error">{{ snackbarText }}</v-snackbar>

    <ConfirmDialog ref="confirm"></ConfirmDialog>
  </span>
</template>

<script>
/*eslint-disable */
import SelectionTable from "./SelectionTable.vue";
import MultipleSelectionTable from "./MultipleSelectionTable.vue";
import SelectionList from "./SelectionList.vue";
import MultipleSelectionList from "./MultipleSelectionList.vue";
import DataPicker from "./DataPicker.vue";
import ConfirmDialog from "./ConfirmDialog.vue";

import mixin from "../mixin/MixingCommonComp";
import TabGridTable from "./TabGridTable.vue";

export default {
  name: "Tab",
  mixins: [mixin],
  components: {
    SelectionTable,
    SelectionList,
    MultipleSelectionTable,
    MultipleSelectionList,
    DataPicker,
    TabGridTable,
    ConfirmDialog,
  },
  props: {
    value: { type: Number },
    parent_id: { type: Object, default: null },
    ad_tab_id: { type: Number },
    language: { type: String, default: "it_IT" },
    hideSave: { type: Boolean, default: false },
    hideTabs: { type: Boolean, default: false },
    hideDelete: { type: Boolean, default: false },
    hideCopy: { type: Boolean, default: false },
  },
  data() {
    return {
      tab: null,
      tabs: 0,
      record: {},
      form: false,
      rules: {
        mandatory: (value) => !!value || "Campo obbligatorio",
      },
      snackbar: false,
      snackbarText: "",
      dataFetched: false,
      copyValues:{}
    };
  },
  methods: {
    getTab() {
      let parameters = {};
      parameters.data = { ad_tab_id: this.ad_tab_id };
      parameters.idempiereRestPath = "window/getTab";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.tab = response.data.tab;
        this.getData();
      });
    },
    async getData() {
      this.dataFetched = false;
      let parameters = {};
      parameters.data = {
        ad_tab_id: this.tab.AD_Tab_ID,
        record_id: this.record_id,
      };
      parameters.idempiereRestPath = "window/getData";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.record = response.data.record;
          if (!this.record) {
            this.record_id = 0;
            this.record = {};
          }
          if (this.record_id == 0 && this.parent_id) {
            this.record[this.parent_id.name] = this.parent_id.value;
          }
          for (let field of this.tab.Fields) {
            if (
              !this.record[field.Column.ColumnName] &&
              field.Column.DefaultValue
            ) {
              /*Skip @Parameters@ */

              this.record[field.Column.ColumnName] = field.Column.DefaultValue;
            }
          }
          //this.$refs.form.validate();
          this.dataFetched = true;
          this.$emit('datafetched', this.record);
        });
    },
    getRules(field) {
      let rules = [];

      if(this.isMandatory(field)){
        rules.push(this.rules.mandatory);
      }

      return rules;
    },
    isMandatory(field){
      if (field.IsMandatory == "Y") {
        return true;
      }

      if(field.IsMandatory == null && field.Column.IsMandatory){
        return true;
      }

      let mandatoryLogic = null;

      if(field.MandatoryLogic){
         mandatoryLogic = field.MandatoryLogic;
      }

      if(mandatoryLogic == null && field.Column.MandatoryLogic){
        mandatoryLogic = field.Column.MandatoryLogic;
      }

      if(mandatoryLogic == null){
        return false;
      }

      let logic = mandatoryLogic.trim();

      let orConditions = logic.split("|");

      for (let condition of orConditions) {
        condition = condition.trim();
        let splitted = this.splitFormula(condition);

        if (splitted[0].match(/@*@/)) {
          splitted[0] = splitted[0].replaceAll("@", "");
          splitted[0] = this.record[splitted[0]];
        }
        splitted[0] = "'" + splitted[0] + "'";

        if (splitted[2].match(/@*@/)) {
          splitted[2] = splitted[1].replaceAll("@", "");
          splitted[2] = this.record[splitted[1]];
        }
        splitted[2] = "'" + splitted[2] + "'";
        let formula = splitted.join("");

        if(eval(formula)) return true;
      }
      return false;
    },
    isDisabled(field) {
      if (field.Column.IsUpdateable) return false;
      return this.record_id > 0;
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.showMandatoryError();
        return;
      }

      /*Get signature Field */
      for (let field of this.tab.Fields) {
        if (!field.IsDisplayed) continue;
        if (field.Column.Reference.Name != "Signature") continue;
        if (!this.isDisplayed(field)) continue;

        let ref = this.$refs[field.Column.ColumnName][0];
        let signature = "";
        if (!ref.isEmpty()) {
          signature = ref.saveSignature().data.split(",")[1];
        }

        this.record[field.Column.ColumnName] = signature;
      }

      let parameters = {};
      parameters.data = {
        record_id: this.record_id,
        record: this.record,
        ad_tab_id: this.tab.AD_Tab_ID,
      };
      parameters.idempiereRestPath = "window/saveRecord";
      let response = await this.$store.dispatch(
        "callIdempiereRest",
        parameters
      );
      if (response.data.result != "OK") {
        this.showErrorDialog(response.data.message);
        this.$emit("save",false)
      } else {
        this.record_id = response.data.record_id;
        this.showSuccessDialog("Salvataggio effettuato!", 900);
        this.$emit("save",true)
      }
      await this.getData();
      return response;
    },
    async deleteRecord() {
      if (
        !(await this.$refs.confirm.open(
          "Elimina",
          "Vuoi eliminare questo record"
        ))
      ) {
        return;
      }

      let parameters = {};
      parameters.data = {
        ad_tab_id: this.ad_tab_id,
        record_id: this.record_id,
      };
      parameters.idempiereRestPath = "window/deleteRecord";
      let response = await this.$store.dispatch(
        "callIdempiereRest",
        parameters
      );
      if (response.data.result == "OK") {
        this.record_id = 0;
        this.getData();
        this.$emit("delete",true)
      } else {
        this.showErrorDialog(response.data.message);
        this.$emit("save",false)
      }
      return response;
    },
    getNormalizerFactor(rows) {
      let maxCols = 0;
      for (let row of rows) {
        let colSum = 0;
        for (let field of row.fields) {
          colSum += field.cols;
        }
        if (colSum > maxCols) {
          maxCols = colSum;
        }
      }
      return Math.floor(12 / maxCols);
    },
    showMandatoryError() {
      let error = "Campi obbligatori: ";
      for (let field of this.tab.Fields) {
        if (field.Column.IsKey) continue;
        if (field.Column.Reference.Name == "Yes-No") continue;

        if (
          (field.IsMandatory || field.Column.IsMandatory) &&
          !this.record[field.Column.ColumnName]
        ) {
          error += field.Trl[this.language].Name + ",";
        }
      }
      error = error.slice(0, -1);
      this.snackbarText = error;
      this.snackbar = true;
    },
    clearSignature(field) {
      this.$refs[field.Column.ColumnName][0].clearSignature();
    },
    async copyRecord() {
      if (!this.dataFetched) {
        setTimeout(this.copyRecord, 1000);
        return;
      }
      if (
        !(await this.$refs.confirm.open(
          "Copia",
          "Vuoi copiare questo record ?"
        ))
      ) {
        return;
      }
      let parameters = {};
      parameters.data = parameters.idempiereRestPath = "";
      parameters.data = {
        ad_tab_id: this.ad_tab_id,
        record_id: this.record_id,
        record:this.copyValues
      };
      parameters.idempiereRestPath = "window/copyRecord";
      let response = await this.$store.dispatch(
        "callIdempiereRest",
        parameters
      );
      if (response.data.result == "OK") {
        this.record_id = response.data.record_id;
        this.$emit("copy",true)
      } else {
        this.showErrorDialog(response.data.message);
        this.$emit("copy",false)
      }
      return response.data.result == "OK";
    },
    setValue(name, value) {
      if (this.dataFetched) {
        let record = { ...this.record };
        record[name] = value;
        this.record = { ...record };
      } else {
        setTimeout(this.setValue, 1000, name, value);
      }
    },
    setCopyValue(name, value) {
      this.copyValues[name]=value
    },
    getValue(name) {
      if (this.dataFetched) {
        let record = { ...this.record };
        return record[name];
      } else {
        setTimeout(this.setValue, 1000, name);
      }
    },
    isDisplayed(field) {
      if (!field.IsActive) return false;
      if (!field.IsDisplayed) return false;
      if (!field.DisplayLogic) return true;

      let logic = field.DisplayLogic.trim();

      let orConditions = logic.split("|");

      for (let condition of orConditions) {
        condition = condition.trim();
        let splitted = this.splitFormula(condition);

        if (splitted[0].match(/@*@/)) {
          splitted[0] = splitted[0].replaceAll("@", "");
          splitted[0] = this.record[splitted[0]];
        }
        splitted[0] = "'" + splitted[0] + "'";

        if (splitted[2].match(/@*@/)) {
          splitted[2] = splitted[1].replaceAll("@", "");
          splitted[2] = this.record[splitted[1]];
        }
        splitted[2] = "'" + splitted[2] + "'";
        let formula = splitted.join("");

        if(eval(formula)) return true;
      }
      return false;
    },
    splitFormula(formula) {
      let splitted = [];
      let splitter = "";
      if (formula.match(/*=*/)) {
        splitted = formula.split("=");
        splitter = "==";
      } else if (formula.match(/*>*/)) {
        splitted = formula.split(">");
        splitter = ">";
      } else if (formula.match(/*>=*/)) {
        splitted = formula.split(">=");
        splitter = ">=";
      } else if (formula.match(/*<*/)) {
        splitted = formula.split("<");
        splitter = "<";
      } else if (formula.match(/*<=*/)) {
        splitted = formula.split("<=");
        splitter = "<=";
      } else if (formula.match(/*!=*/)) {
        splitted = formula.split("!=");
        splitter = "!=";
      }
      return [
        splitted[0].replaceAll("'", ""),
        splitter.replaceAll("'", ""),
        splitted[1].replaceAll("'", ""),
      ];
    },
    emitChange(field){
      this.$emit('change', {field:field, value:this.record[field.Column.ColumnName] })
    }
  },
  mounted() {
    this.getTab();
  },
  computed: {
    record_id: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    fields() {
      if (!this.tab) return [];
      let fields = this.tab.Fields;
      fields.sort((a, b) => {
        return a.SeqNo > b.SeqNo ? 1 : -1;
      });
      let row = {
        fields: [],
      };
      let rows = [];
      let prevx = 0;
      for (let field of fields) {
        if (!this.isDisplayed(field)) continue;
        if (
          field.Column.Reference.Name == "Button" &&
          field.Column.IsToolbarButton == "Y"
        )
          continue;

        if (field.XPosition < prevx) {
          if (row.fields.length > 0) {
            rows.push(row);
            row = {
              fields: [],
            };
          }
        }
        row.fields.push(field);
        prevx = field.XPosition + field.ColumnSpan;
      }
      rows.push(row);
      /*Cal colspan */
      for (let row of rows) {
        for (let index in row.fields) {
          index = parseInt(index);
          let field = row.fields[index];
          let nextField = row.fields[index + 1];
          let prevField = row.fields[index - 1];
          if (field.Included_Tab_ID) {
            fields.cols = 12;
          } else if (nextField != null && prevField != null) {
            field.cols =
              field.ColumnSpan +
              (nextField.XPosition - (field.XPosition + field.ColumnSpan));
            field.offset =
              field.XPosition - (prevField.XPosition + prevField.ColumnSpan) -1;
          } else if (prevField != null && nextField == null) {
            field.cols = field.ColumnSpan + 1;
            field.offset =
              field.XPosition -
              (prevField.XPosition + prevField.ColumnSpan) -
              1;
          } else if (nextField != null && prevField == null) {
            field.cols = field.ColumnSpan + 1;
            field.offset = 0
          } else {
            field.cols = field.ColumnSpan + 1;
            field.offset = field.XPosition - 1;
          }
        }
      }
      /*Normalize column*/
      let factor = this.getNormalizerFactor(rows);
      for (let row of rows) {
        for (let field of row.fields) {
          field.cols = field.cols * factor;
        }
      }
      /* Grups */
      for (let row of rows) {
        for (let field of row.fields) {
          if (field.AD_FieldGroup_ID > 0) {
            row.group = field.FieldGroup.Trl[this.language].Name;
            break;
          }
        }
      }
      return rows;
    },
  },
  watch: {
    value: function () {
      this.getData();
    },
    ad_tab_id: function () {
      this.getTab();
    },
  },
};
</script>

<style>
.col {
  padding: 5px !important;
}

.v-input--checkbox {
  margin-top: 0 !important;
}
</style>